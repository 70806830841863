import useAsclepius from "@/composables/api/asclepius"
import { reactive, Ref, ref, watch } from "vue"
import {
  BenefitsApiListCompanyBenefitEnrollmentsRequest,
  CompanyBenefitDTO,
  CompanyBenefitEnrollment
} from "@vitable/asclepius-api-client-ts"

export const useGetCompanyBenefits = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()
  const benefits: Ref<Array<CompanyBenefitDTO>> = ref([])

  async function getCompanyBenefits(companyId: string) {
    await call(() =>
      getBenefitsApi().listActiveCompanyBenefits({
        companyId: companyId
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      benefits.value = newValue.data.benefits
    }
  })

  return {
    benefits,
    getCompanyBenefits,
    getCompanyBenefitsResult: callResult
  }
}

export const useGetBenefitPlanEnrollments = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()
  const enrollmentsPaginated = reactive<Paginated<CompanyBenefitEnrollment>>({
    currentPage: 1,
    nextPage: undefined,
    prevPage: undefined,
    totalCount: 0,
    results: []
  })

  async function getCompanyBenefitEnrollments(request: BenefitsApiListCompanyBenefitEnrollmentsRequest) {
    request = { ...request, page: enrollmentsPaginated.currentPage }
    await call(() => getBenefitsApi().listCompanyBenefitEnrollments(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      enrollmentsPaginated.results = newValue.data.results
      enrollmentsPaginated.nextPage = newValue.data.next
      enrollmentsPaginated.prevPage = newValue.data.previous
      enrollmentsPaginated.totalCount = newValue.data.count
    }
  })

  return {
    enrollmentsPaginated,
    getCompanyBenefitEnrollments,
    getCompanyBenefitEnrollmentsResult: callResult
  }
}

export const useGetCompanyBenefit = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()
  const companyBenefit: Ref<Nullable<CompanyBenefitDTO>> = ref(null)

  async function getCompanyBenefit(companyBenefitId: string) {
    await call(() =>
      getBenefitsApi().getCompanyBenefit({
        companyBenefitId: companyBenefitId
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      companyBenefit.value = newValue.data.benefit
    }
  })

  return {
    companyBenefit,
    getCompanyBenefit,
    getCompanyBenefitResult: callResult
  }
}
