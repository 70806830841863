export const APP_URL: string = import.meta.env.VITE_APP_URL
export const IS_DEV: boolean = import.meta.env.VITE_APP_ENV === "dev"
export const IS_UAT: boolean = import.meta.env.VITE_APP_ENV === "uat"
export const IS_PROD: boolean = import.meta.env.VITE_APP_ENV === "prod"
export const SERVER_URL: string = import.meta.env.VITE_APP_SERVER_URL
export const STRIPE_KEY: string = import.meta.env.VITE_APP_STRIPE_KEY
export const FINCH_URL: string = import.meta.env.VITE_APP_FINCH_URL
export const SLACK_TOKEN: string = import.meta.env.VITE_APP_SLACK_TOKEN
export const FRESHPAINT_TOKEN: string = import.meta.env.VITE_APP_FRESHPAINT_TOKEN
export const SMARTY_KEY: string = import.meta.env.VITE_APP_SMARTY_KEY
export const SENTRY_URL = "https://cd56f47d0cd74d34b699f8f8ac4fd5de@o675910.ingest.sentry.io/5770691"

export const QUEST_LAB_LOCATOR_LINK = "https://appointment.questdiagnostics.com/find-location/as-location-finder"
export const LABCORP_LAB_LOCATOR_LINK = "https://www.labcorp.com/labs-and-appointments"
export const LABCORP_DRUG_AND_ALCOHOL_LAB_LOCATOR_LINK = "https://www.labcorpsolutions.com/ots/locator.do"
export const CVS_MINUTE_CLINIC_LOCATOR_LINK = "https://www.cvs.com/minuteclinic/clinic-locator/"

export const METABASE_SITE_URL = "https://metabase.vitablehealth.com"
export const METABASE_SECRET_KEY = "0175b323a3bd97c1056de694a57f178afd7bdff3350bb06b46d89868496e674e"
export const MOBILE_APP_DOWNLOAD_LINK = "https://onelink.to/gu5jhv"
