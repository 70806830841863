import { createRouter, createWebHistory } from "vue-router"

import { dashboardRoutes } from "@/router/routes/dashboard-routes"

import { routes as loginAuthRoutes } from "@/modules/login/router/routes"
import { routes as setupAuthRoutes } from "@/modules/setup/router/routes"
import { routes as occupationalHealthRoutes } from "@/modules/occupational-health/router/routes"
import { routes as clinicCheckInRoutes } from "@/modules/clinic-checkin/router/routes"
import { routes as companyCustomPagesRoutes } from "@/modules/company-custom-pages/router/routes"
import { routes as onboardingRoutes } from "@/modules/onboarding/router/routes"
import { routes as signupRoutes } from "@/modules/signup/router/routes"

import analyticsService from "@/services/analytics/analytics-service"
import GlobalGuardians from "@/router/global-guardians"
import GuardianRunner from "@/router/guardian-runner"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      redirect: { name: "login" }
    },

    ...loginAuthRoutes,

    ...setupAuthRoutes,

    ...dashboardRoutes,

    ...clinicCheckInRoutes,

    ...companyCustomPagesRoutes,

    ...occupationalHealthRoutes,

    ...signupRoutes,

    ...onboardingRoutes
  ],
  scrollBehavior(to, _from, _savedPosition) {
    if (to.hash) {
      document!.getElementById(to.hash.replace("#", ""))!.scrollIntoView({ behavior: "smooth" })
    } else {
      const dashboardEl = document.getElementById("content")
      if (dashboardEl) {
        dashboardEl.scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0)
      }
    }
  }
})

router.afterEach((to, from) => {
  analyticsService.pageViewed({
    name: to.name,
    path: to.path,
    from_name: from ? from.name : null,
    from_path: from ? from.path : null
  })
})

GuardianRunner.init({
  globalGuardians: GlobalGuardians.getGlobalGuardians()
})

export default router
