import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import { version } from "../../package.json"
import { App } from "@vue/runtime-core"
import router from "@/router"
import { IS_DEV, SENTRY_URL } from "@/config"

let _initIn = false

function init(app: App) {
  if (_initIn) throw new Error("Sentry was already initialized!")

  if (IS_DEV) return (_initIn = true)

  Sentry.init({
    app,
    dsn: SENTRY_URL,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        // tracePropagationTargets: [appUrl, /^\//]
      })
    ],
    tracesSampleRate: 0.25,
    environment: import.meta.env.VITE_APP_ENV,
    release: version,
    logErrors: IS_DEV
  })

  app.config.errorHandler = (error: any) => {
    Sentry.captureException(error)
  }

  window.addEventListener("error", (event) => {
    Sentry.captureException(event)
  })
  window.addEventListener("unhandledrejection", (event) => {
    Sentry.captureException(event)
  })

  _initIn = true
}

export default {
  init
}
