import { ref, watch } from "vue"
import useAsclepius from "@/composables/api/asclepius"
import {
  CompanyYouthMembershipPlan,
  GetActiveCompanyYouthMembershipPlanResponse
} from "@vitable/asclepius-api-client-ts/api"

export function useGetActiveCompanyYouthMembershipPlan() {
  const { getYouthMembershipApi, call, callResult } = useAsclepius()
  const activeCompanyYouthMembershipPlan = ref<CompanyYouthMembershipPlan | null>()

  async function getActiveCompanyYouthMembershipPlan(companyId: string): Promise<void> {
    await call(() => getYouthMembershipApi().getActiveCompanyYouthMembershipPlan({ companyId }))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data: GetActiveCompanyYouthMembershipPlanResponse = newValue.getData()
      activeCompanyYouthMembershipPlan.value = data.plan
    }
  })

  return {
    getActiveCompanyYouthMembershipPlan,
    getActiveCompanyYouthMembershipPlanResult: callResult,
    activeCompanyYouthMembershipPlan
  }
}
