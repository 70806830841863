import { NavigationGuard } from "vue-router"
import { ensureIsAuthenticated, ensureIsCompanyAdminActive, ensureIsCompanyOnboarded } from "@/router/guardians"

/*
    A set of useful util function for working with Guardians.
    Most prominently, guardian chains.
 */

export function getGuardianChainForEnsuringAuthOnboardedAndActive(): Array<NavigationGuard> {
  return [ensureIsAuthenticated, ensureIsCompanyOnboarded, ensureIsCompanyAdminActive]
}
