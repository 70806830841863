import analyticsService from "@/services/analytics/analytics-service"
import delightedPmfSurveyService from "@/services/delighted-pmf-survey-service"
import { useUserStore } from "@/store/user-store"
import { getActivePinia } from "pinia"

async function handleLogInEvent() {
  analyticsService.loggedIn(useUserStore().user!)
  delightedPmfSurveyService.potentiallyShowPmfSurvey()
}

async function handleLogOutEvent() {
  analyticsService.loggedOut()
  _clearAllStores()
}

function _clearAllStores() {
  // @ts-ignore -- clear all stores
  getActivePinia()._s.forEach((store) => store.$reset())
}

export default {
  handleLogInEvent,
  handleLogOutEvent
}
