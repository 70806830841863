import router from "@/router"
import { NavigationGuard } from "vue-router"
import { useUserStore } from "@/store/user-store"
import { useSubscriptionStore } from "@/store/subscription-store"
import { useCompanyStore } from "@/store/company-store"

export const ensureIsAnonymous: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  if (userStore.isLoggedIn) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureIsAuthenticated: NavigationGuard = (to, from, next) => {
  const isLoggedIn = useUserStore().isLoggedIn
  if (!isLoggedIn) {
    router.push({ name: "login", query: { redirectedFromPath: to.fullPath } }).then()
    return false
  }

  return true
}

export const ensureIsCompanyOnboarded: NavigationGuard = (to, from, next) => {
  const isCompanyOnboarded = useCompanyStore().companyOnboardedIn
  if (!isCompanyOnboarded) {
    router.push({ name: "onboarding" }).then()
    return false
  }

  return true
}

export const ensureIsCompanyNotOnboarded: NavigationGuard = (to, from, next) => {
  const isCompanyOnboarded = useCompanyStore().companyOnboardedIn
  if (isCompanyOnboarded) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureIsCompanyAdminActive: NavigationGuard = (to, from, next) => {
  const isActive = useUserStore().isActive
  if (!isActive) {
    router.push({ name: "activate" }).then()
    return false
  }

  return true
}

export const ensureIsCompanyAdminNotActive: NavigationGuard = (to, from, next) => {
  const isActive = useUserStore().isActive
  if (isActive) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureHasBenefitsGuardian: NavigationGuard = (to, from, next) => {
  const hasBenefits = useSubscriptionStore().hasBenefitsIn
  if (!hasBenefits) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureHasLabOrderingFeatureGuardian: NavigationGuard = (to, from, next) => {
  const hasLabOrderingFeature = useSubscriptionStore().hasLabOrderingFeature
  if (!hasLabOrderingFeature) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureHasServiceRequestingFeature: NavigationGuard = (to, from, next) => {
  const hasServiceRequestingFeature = useSubscriptionStore().hasServiceRequestingFeature
  if (!hasServiceRequestingFeature) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureHasClinicsFeature: NavigationGuard = (to, from, next) => {
  const hasClinicsFeature = useSubscriptionStore().hasClinicPlanIn
  if (!hasClinicsFeature) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureHasYouthClinicsFeature: NavigationGuard = (to, from, next) => {
  const hasYouthClinicsFeature = useSubscriptionStore().hasYouthClinicsIn
  if (!hasYouthClinicsFeature) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}

export const ensureHasOccHealthFeature: NavigationGuard = (to, from, next) => {
  const hasOccHealthFeature = useSubscriptionStore().hasOccHealthPlanIn
  if (!hasOccHealthFeature) {
    router.push({ name: "home" }).then()
    return false
  }

  return true
}
