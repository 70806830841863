import useAsclepius from "@/composables/api/asclepius"
import { ref, watch } from "vue"
import { useCompanyStore } from "@/store/company-store"
import { OccupationalHealthRecordDetailed } from "@vitable/asclepius-api-client-ts"

export const useListOccupationalHealthRecords = () => {
  const { getMedicalApi, call, callResult } = useAsclepius()
  const companyStore = useCompanyStore()

  const ohrs = ref(null)
  const currPage = ref(1)
  const nextPage = ref(null)
  const prevPage = ref(null)
  const count = ref(null)

  async function listOhrs({
    approvedOnDateGte,
    completedOnDateGte,
    ohrTypeId,
    memberId,
    memberName,
    occupationalHealthLabOrderRequestId,
    urgentCareOrderRequestId,
    tagsIds
  }: {
    approvedOnDateGte?: string
    completedOnDateGte?: string
    ohrTypeId?: string
    memberId?: string
    memberName?: string
    occupationalHealthLabOrderRequestId?: string
    urgentCareOrderRequestId?: string
    tagsIds?: Array<string>
  }) {
    await call(() =>
      getMedicalApi().listOccupationalHealthRecordsByCompany({
        companyId: companyStore.companyId!,
        approvedOnGte: approvedOnDateGte,
        completedOnGte: completedOnDateGte,
        ohrTypeId,
        memberId,
        memberName,
        occupationalHealthLabOrderRequestId,
        urgentCareOrderRequestId,
        includeTagsIn: true,
        enteredInErrorIn: false,
        tagsIds: tagsIds,
        page: currPage.value
      })
    )
  }

  watch(callResult, () => {
    if (callResult.value.isSuccess()) {
      const data = callResult.value.getData()
      ohrs.value = data.results
      nextPage.value = data.next
      prevPage.value = data.previous
      count.value = data.count
    }
  })

  return {
    listOhrs,
    listOhrsResult: callResult,
    ohrs,
    currPage,
    nextPage,
    prevPage,
    count
  }
}

export const useGetOccupationalHealthRecord = () => {
  const { getMedicalApi, call, callResult } = useAsclepius()
  const ohr = ref<OccupationalHealthRecordDetailed>()

  async function getOhr(ohrId: string) {
    await call(() =>
      getMedicalApi().getOccupationalHealthRecord({
        occupationalHealthRecordId: ohrId
      })
    )
  }

  watch(callResult, () => {
    if (callResult.value.isSuccess()) {
      const data = callResult.value.getData()
      ohr.value = data.occupational_health_record
    }
  })

  return {
    getOhr,
    getOhrResult: callResult,
    ohr
  }
}

export const useGetOccupationalHealthRecordTypes = () => {
  const { getMedicalApi, call, callResult } = useAsclepius()
  const ohrTypes = ref([])

  async function getOhrTypes() {
    await call(() => getMedicalApi().listOccupationalHealthRecordTypes())
  }

  watch(callResult, () => {
    if (callResult.value.isSuccess()) {
      const data = callResult.value.getData()
      ohrTypes.value = data.occupational_health_record_types
    }
  })

  return {
    getOhrTypes,
    getOhrTypesResult: callResult,
    ohrTypes
  }
}
