import moment from "moment"
import { defineStore } from "pinia"
import { useCompanyStore } from "@/store/company-store" // @ts-ignore
import {
  EMIConnectionStatusEnum,
  EMISyncErrorTypeEnum,
  EMISyncOperationEventTypeEnum,
  EMISystemTypeEnum,
  WorkforceIntegration
} from "@vitable/asclepius-api-client-ts"
import {
  useListSyncOperationEvents,
  useListWorkforceIntegrations
} from "@/modules/workforce-integrations/composables/workforce-integration"

interface StoreState {
  integrations: Array<WorkforceIntegration>
  addedEventsLast30DaysCount: number
  removedEventsLast30DaysCount: number
  failedEventsLast24HrsCount: number
}

export const useWorkforceIntegrationsStore = defineStore("workforceIntegrationsStore", {
  persist: true,
  state: (): StoreState => {
    return {
      integrations: [],
      addedEventsLast30DaysCount: 0,
      removedEventsLast30DaysCount: 0,
      failedEventsLast24HrsCount: 0
    }
  },
  getters: {
    inactiveIntegrations(): Array<WorkforceIntegration> {
      return this.integrations.filter((i) => i.status === EMIConnectionStatusEnum.Inactive)
    },
    availableIntegrations(): Array<WorkforceIntegration> {
      return this.integrations.filter((i) => i.status !== EMIConnectionStatusEnum.Terminated)
    },
    nonTerminatedHris(): Nullable<WorkforceIntegration> {
      const integrations = this.integrations.filter(
        (i) => i.system_type === EMISystemTypeEnum.Hris && i.status !== EMIConnectionStatusEnum.Terminated
      )
      if (integrations.length > 0) {
        return integrations[0]
      }
      return null
    }
  },
  actions: {
    async loadWorkforceIntegrations() {
      const companyStore = useCompanyStore()
      const { integrations, listIntegrations } = useListWorkforceIntegrations()

      await listIntegrations({
        companyId: companyStore.companyId!
      })

      this.integrations = integrations.value
    },

    async loadRecentEventCounts() {
      const companyStore = useCompanyStore()
      const { listSyncEvents, syncEventsPaginated } = useListSyncOperationEvents()

      const createdAfter30Days = moment().subtract(31, "days").toDate().toISOString().split("T")[0]
      const createdAfter1Day = moment().subtract(1, "days").toDate().toISOString().split("T")[0]

      await listSyncEvents({
        companyId: companyStore.companyId!,
        createdAfter: createdAfter30Days,
        eventType: EMISyncOperationEventTypeEnum.Added,
        errorOccurredIn: false
      })

      this.addedEventsLast30DaysCount = syncEventsPaginated.totalCount

      await listSyncEvents({
        companyId: companyStore.companyId!,
        createdAfter: createdAfter30Days,
        eventType: EMISyncOperationEventTypeEnum.Removed,
        errorOccurredIn: false
      })

      this.removedEventsLast30DaysCount = syncEventsPaginated.totalCount

      await listSyncEvents({
        companyId: companyStore.companyId!,
        createdAfter: createdAfter1Day,
        eventType: undefined,
        errorOccurredIn: true,
        errorType: EMISyncErrorTypeEnum.InvalidDemographics
      })

      this.failedEventsLast24HrsCount = syncEventsPaginated.totalCount
    }
  }
})
