import { defineStore } from "pinia"
import {
  BenefitCarrierEnum,
  CompanyBenefitDTO,
  CompanySubscription,
  CompanyYouthMembershipPlan,
  EmployeeBenefitEligibilityPolicy,
  Entitlement,
  FeatureNameEnum,
  MedicalService
} from "@vitable/asclepius-api-client-ts"
import { useCompanyStore } from "@/store/company-store" // @ts-ignore
import { useGetActiveCompanySubscription } from "@/composables/subscription"
import { useGetCompanyBenefits } from "@/modules/benefits/composables/benefit"
import { useGetCompanyEntitledMedicalServices, useGetCompanyEntitlements } from "@/composables/entitlements"
import { useGetActiveBenefitEligibilityPolicy } from "@/modules/benefits/composables/benefit-eligibility"
import { useGetActiveCompanyYouthMembershipPlan } from "@/composables/youth-membership"

export interface SubscriptionState {
  activeSubscription: Nullable<CompanySubscription>
  companyBenefits: Array<CompanyBenefitDTO>
  entitlements: Array<Entitlement>
  medicalServices: Array<MedicalService>
  benefitEligibilityPolicy: Nullable<EmployeeBenefitEligibilityPolicy>
  activeCompanyYouthMembershipPlan: Nullable<CompanyYouthMembershipPlan>
}

export const useSubscriptionStore = defineStore("subscriptionStore", {
  persist: true,
  state: (): SubscriptionState => {
    return {
      activeSubscription: null,
      companyBenefits: [],
      entitlements: [],
      medicalServices: [],
      benefitEligibilityPolicy: null,
      activeCompanyYouthMembershipPlan: null
    }
  },
  getters: {
    hasActiveSubscriptionIn(): boolean {
      return !!this.activeSubscription
    },
    hasClinicPlanIn(): boolean {
      return this.entitlements.filter((e) => e.feature_name === FeatureNameEnum.OccupationalHealthClinics).length > 0
    },
    hasYouthClinicsIn(): boolean {
      return (
        this.entitlements.filter((e) => e.feature_name === FeatureNameEnum.OccupationalHealthYouthClinics).length > 0
      )
    },
    hasActiveCompanyYouthMembershipPlan(): boolean {
      return this.activeCompanyYouthMembershipPlan !== null
    },
    hasMedicationCoverageIn(): boolean {
      return this.entitlements.filter((e) => e.feature_name === FeatureNameEnum.FreeMedicationCoverage).length > 0
    },
    hasOccHealthPlanIn(): boolean {
      return this.entitlements.findIndex((e) => e.feature_name.toLowerCase().includes("occupational health")) >= 0
    },
    incrementalMembershipUpgradingAllowedIn(): boolean {
      return (
        this.entitlements.filter((e) => e.feature_name === FeatureNameEnum.IncrementalMembershipAllotmentUpgrading)
          .length > 0
      )
    },
    hasUnlimitedMemberships(): boolean {
      if (!this.activeSubscription) return false

      return this.activeSubscription.membership_allotment === null
    },
    hasMentalHealthCoverageIn(): boolean {
      if (!this.hasBenefitsIn) return false
      return (
        this.entitlements.filter(
          (e) => !!e.medical_service_name && e.medical_service_name.toLowerCase().includes("mental health")
        ).length > 0
      )
    },
    clinicAllotment(): number {
      const entitlement = this.entitlements.find((e) => e.feature_name === FeatureNameEnum.OccupationalHealthClinics)
      if (entitlement) return entitlement.quantity!

      return 0
    },

    // TODO: move to retrieving this from a Metrics service
    occurredClinicsCountThisMonth(): number {
      return this.activeSubscription ? this.activeSubscription.occurred_clinics_count_this_month : 0
    },
    usedLabcorpLabVoucherCountThisMonth(): number {
      return this.activeSubscription ? this.activeSubscription.used_labcorp_lab_voucher_count_this_month : 0
    },
    usedLabcorpDrugAndAlcoholVoucherCountThisMonth(): number {
      return this.activeSubscription
        ? this.activeSubscription.used_labcorp_drug_and_alcohol_voucher_count_this_month
        : 0
    },
    usedQuestLabVoucherCountThisMonth(): number {
      return this.activeSubscription ? this.activeSubscription.used_quest_lab_voucher_count_this_month : 0
    },
    usedUrgentCareVoucherCountThisMonth(): number {
      return this.activeSubscription ? this.activeSubscription.used_urgent_care_voucher_count_this_month : 0
    },

    occupationalHealthLabVoucherAllotment(): number {
      return getEntitlementQuantity(FeatureNameEnum.OccupationalHealthLabCorpLabVouchers)
    },

    labCorpOccupationalHealthDrugAndAlcoholVoucherAllotment(): number {
      return getEntitlementQuantity(FeatureNameEnum.OccupationalHealthLabCorpDrugAlcoholVouchers)
    },

    questOccupationalHealthLabVoucherAllotment(): number {
      return getEntitlementQuantity(FeatureNameEnum.OccupationalHealthQuestLabVouchers)
    },

    urgentCareVoucherAllotment(): number {
      return getEntitlementQuantity(FeatureNameEnum.OccupationalHealthUrgentCareVouchers)
    },

    hasLabOrderingFeature(): boolean {
      return (
        this.occupationalHealthLabVoucherAllotment > 0 ||
        this.questOccupationalHealthLabVoucherAllotment > 0 ||
        this.labCorpOccupationalHealthDrugAndAlcoholVoucherAllotment > 0
      )
    },

    hasServiceRequestingFeature(): boolean {
      return (
        this.occupationalHealthLabVoucherAllotment > 0 ||
        this.questOccupationalHealthLabVoucherAllotment > 0 ||
        this.labCorpOccupationalHealthDrugAndAlcoholVoucherAllotment > 0 ||
        this.urgentCareVoucherAllotment > 0
      )
    },

    hasBenefitsIn(): boolean {
      return this.companyBenefits.length > 0
    },

    vpcCompanyBenefits(): Array<CompanyBenefitDTO> {
      return this.companyBenefits.filter((b) => b.carrier === BenefitCarrierEnum.Vitable)
    },

    liferaftCompanyBenefits(): Array<CompanyBenefitDTO> {
      return this.companyBenefits.filter((b) => b.carrier === BenefitCarrierEnum.Liferaft)
    },

    mecBenefit(): Optional<CompanyBenefitDTO> {
      return this.liferaftCompanyBenefits.find(
        (b) => b.internal_name === "Vitable MEC Benefit" && !b.scheduled_for_cancellation_in
      )
    },

    vpcBenefit(): Optional<CompanyBenefitDTO> {
      return this.vpcCompanyBenefits.find((b) => !b.scheduled_for_cancellation_in)
    },

    primaryActiveBenefit(): Nullable<CompanyBenefitDTO> {
      if (this.mecBenefit) return this.mecBenefit
      if (this.vpcBenefit) return this.vpcBenefit

      return null
    },

    hasActiveBenefitEligibilityPolicy(): boolean {
      return this.benefitEligibilityPolicy !== null
    },

    hasHrPlatformIntegrationPermission(): boolean {
      return this.entitlements.filter((e) => e.feature_name === FeatureNameEnum.HrPlatformIntegration).length > 0
    }
  },

  actions: {
    async loadActiveCompanySubscription() {
      const companyStore = useCompanyStore()
      const { getActiveCompanySubscription, companySubscription } = useGetActiveCompanySubscription()

      await getActiveCompanySubscription({
        companyId: companyStore.companyId!
      })

      this.activeSubscription = companySubscription.value
    },

    async loadActiveBenefitEligibilityPolicy() {
      const companyStore = useCompanyStore()
      const { getActiveBenefitEligibilityPolicy, policy } = useGetActiveBenefitEligibilityPolicy()

      await getActiveBenefitEligibilityPolicy({
        companyId: companyStore.companyId!
      })

      this.benefitEligibilityPolicy = policy.value
    },

    async loadEntitlements() {
      const companyStore = useCompanyStore()
      const { entitlements, getCompanyEntitlements } = useGetCompanyEntitlements()
      await getCompanyEntitlements(companyStore.companyId!)
      this.entitlements = entitlements.value
    },

    async loadBenefits() {
      const companyStore = useCompanyStore()
      const { benefits, getCompanyBenefits } = useGetCompanyBenefits()
      await getCompanyBenefits(companyStore.companyId!)
      this.companyBenefits = benefits.value
    },

    async loadMedicalServices() {
      const companyStore = useCompanyStore()
      const { medicalServices, getCompanyEntitledMedicalServices } = useGetCompanyEntitledMedicalServices()
      await getCompanyEntitledMedicalServices(companyStore.companyId!)
      this.medicalServices = medicalServices.value
    },

    async loadActiveCompanyYouthMembershipPlan() {
      const companyStore = useCompanyStore()
      const { getActiveCompanyYouthMembershipPlan, activeCompanyYouthMembershipPlan } =
        useGetActiveCompanyYouthMembershipPlan()
      await getActiveCompanyYouthMembershipPlan(companyStore.companyId!)
      this.activeCompanyYouthMembershipPlan = activeCompanyYouthMembershipPlan.value ?? null
    }
  }
})

function getEntitlementQuantity(featureName: FeatureNameEnum): number {
  const store = useSubscriptionStore()
  const entitlement = store.entitlements.find((e) => e.feature_name === featureName)
  if (entitlement) return entitlement.quantity!
  return 0
}
