import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"
import { ensureIsAnonymous } from "@/router/guardians"

export const routes = [
  {
    path: "/",
    name: "auth-layout-login",
    component: () => import("@/layouts/auth/AuthLayout.vue"),
    children: [
      {
        path: "/",
        name: "login",
        component: () => import("@/modules/login/pages/Login.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [ensureIsAnonymous])
        }
      }
    ]
  }
]
