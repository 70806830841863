import useAsclepius from "@/composables/api/asclepius"
import { reactive, ref, Ref, watch } from "vue"
import eventBus from "@/events/global-event-bus"
import memberEvents from "@/modules/member-management/events/events"
import {
  BenefitsApiCreateEmployeeBenefitEligibilityPolicyRequest,
  BenefitsApiGetActiveEmployeeBenefitEligibilityPolicyRequest,
  BenefitsApiListEmployeeBenefitEligibilitiesRequest,
  BenefitsApiTerminateEmployeeBenefitEligibilityRequest,
  EmployeeBenefitEligibility,
  EmployeeBenefitEligibilityPolicy
} from "@vitable/asclepius-api-client-ts"

export const useGrantBenefitEligibilityToEmployee = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()

  async function grantBenefitEligibility(companyMemberId: string) {
    await call(() =>
      getBenefitsApi().grantBenefitEligibilityToEmployee({
        grantBenefitEligibilityToEmployeeRequest: {
          company_member_id: companyMemberId
        }
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    grantBenefitEligibility,
    grantBenefitEligibilityResult: callResult
  }
}

export const useTerminateEmployeeBenefitsEligibility = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()

  async function terminateEmployeeBenefitsEligibility(request: BenefitsApiTerminateEmployeeBenefitEligibilityRequest) {
    await call(() => getBenefitsApi().terminateEmployeeBenefitEligibility(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    terminateEmployeeBenefitsEligibility,
    terminateEmployeeBenefitsEligibilityResult: callResult
  }
}

export const useGetActiveBenefitEligibilityPolicy = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()
  const policy: Ref<Nullable<EmployeeBenefitEligibilityPolicy>> = ref(null)

  async function getActiveBenefitEligibilityPolicy(
    request: BenefitsApiGetActiveEmployeeBenefitEligibilityPolicyRequest
  ) {
    await call(() => getBenefitsApi().getActiveEmployeeBenefitEligibilityPolicy(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      policy.value = callResult.value.getData().eligibility_policy
    }
  })

  return {
    getActiveBenefitEligibilityPolicy,
    getActiveBenefitEligibilityPolicyResult: callResult,
    policy
  }
}

export const useCreateBenefitEligibilityPolicy = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()
  const policy: Ref<Nullable<EmployeeBenefitEligibilityPolicy>> = ref(null)

  async function createBenefitEligibilityPolicy(request: BenefitsApiCreateEmployeeBenefitEligibilityPolicyRequest) {
    await call(() => getBenefitsApi().createEmployeeBenefitEligibilityPolicy(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      policy.value = callResult.value.getData().policy
    }
  })

  return {
    createBenefitEligibilityPolicy,
    createBenefitEligibilityPolicyResult: callResult,
    policy
  }
}

export const useListBenefitEligibilities = () => {
  const { getBenefitsApi, call, callResult } = useAsclepius()
  const eligibilitiesPaginated = reactive<Paginated<EmployeeBenefitEligibility>>({
    currentPage: 1,
    nextPage: undefined,
    prevPage: undefined,
    totalCount: 0,
    results: []
  })

  async function listBenefitEligibilities(request: BenefitsApiListEmployeeBenefitEligibilitiesRequest) {
    request = { ...request, page: eligibilitiesPaginated.currentPage }
    await call(() => getBenefitsApi().listEmployeeBenefitEligibilities(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      eligibilitiesPaginated.results = newValue.data.results
      eligibilitiesPaginated.nextPage = newValue.data.next
      eligibilitiesPaginated.prevPage = newValue.data.previous
      eligibilitiesPaginated.totalCount = newValue.data.count
    }
  })

  return {
    listBenefitEligibilities,
    listBenefitEligibilitiesResult: callResult,
    eligibilitiesPaginated
  }
}
