import { useUserStore } from "@/store/user-store"
import { useCompanyStore } from "@/store/company-store"

function potentiallyShowPmfSurvey() {
  const userStore = useUserStore()
  const companyStore = useCompanyStore()

  // @ts-ignore
  delightedPmf.survey({
    email: userStore.user!.email,
    createdAt: new Date(),
    properties: {
      company: companyStore.companyName,
      company_admin_id: userStore.companyAdmin!.id
    }
  })
}

export default {
  potentiallyShowPmfSurvey
}
