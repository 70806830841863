// TODO: This implementation of debounce passes an empty array to the fn() when no args are provided
export function debounce(fn: (...args: unknown[]) => unknown, timeout = 300) {
  let timer: Optional<NodeJS.Timeout> = undefined
  return (...args: unknown[]) => {
    if (timer) return

    timer = setTimeout(() => {
      fn(args)
      timer = undefined
    }, timeout)
  }
}

export function capitalize(word: string) {
  let cappedWord = ""

  for (let i = 0; i < word.length; i++) {
    let char = word.charAt(i).toLowerCase()

    if (i === 0) char = char.toUpperCase()

    cappedWord = cappedWord.concat(char)
  }

  return cappedWord
}

export async function copyTextToClipboard(text: string) {
  const clipboardData = navigator.clipboard
  return await clipboardData.writeText(text)
}

export async function downloadBase64EncodedPngImage(b64EncodedImageData: string, fileName: string) {
  const fileNameAndExt = `${fileName}.png`

  function downloadFileFromBlob(blob: Blob) {
    const a = document.createElement("a")
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileNameAndExt
    a.click()
    window.URL.revokeObjectURL(url)
  }

  fetch(b64EncodedImageData)
    .then(async (res) => {
      const blob = await res.blob()
      downloadFileFromBlob(blob)
      return new File([blob], fileNameAndExt, { type: "image/png" })
    })
    .catch(async (e) => {
      if (e.status && 200 === e.status) {
        const blob = await e.blob()
        downloadFileFromBlob(blob)
        return new File([blob], fileNameAndExt, { type: "image/png" })
      }
      throw e
    })
}

/**
 * Determines if a given hex color is dark or light.
 * Uses luminosity testing with color-blindness correction.
 *
 * @returns {boolean} {@code true} if dark; {@color false} if light
 */
export function isColorDark(hexColor: string) {
  // Convert the hex color to decimal value
  const colorBytes = hexColor.substring(1) // Removes '#'
  const rgb = parseInt(colorBytes, 16)

  // Extract out the individual red, green, and blue colors
  const R = (rgb >> 16) & 0xff
  const G = (rgb >> 8) & 0xff
  const B = (rgb >> 0) & 0xff

  // ITU-R BT.709
  const luma = 0.2126 * R + 0.7152 * G + 0.0722 * B
  return luma < 132.0
}

/**
 * Sanitizes a given phone number to remove '-' and '+' characters.
 * @returns {Promise<*|string>}
 */
export async function sanitizePhoneNumber(phoneNumber: string) {
  return phoneNumber.replaceAll(/\+|-|\s/g, "")
}

export function random(len = 16) {
  return Math.random().toString(36).substr(2, len)
}

export function openInNewTab(url: string) {
  window.open(url, "_blank")
}

export function getFormattedPhoneNumber(phone: string): string {
  if (phone.length === 10) {
    const regex = /^(\d{3})(\d{3})(\d{4})$/
    return phone.replace(regex, "$1.$2.$3")
  } else if (phone.length === 11) {
    const regex = /(\d{1})(\d{3})(\d{3})(\d{4})/
    return phone.replace(regex, "+$1 $2.$3.$4")
  } else {
    return phone
  }
}
