// Event Enum
import { RouteRecordName } from "vue-router"

export enum analyticsEvents {
  PAGE_VIEWED = "page_viewed",
  BUTTON_CLICKED = "button_clicked",
  LOG_IN = "log_in",
  EMPLOYER_SIGNUP_STEP_COMPLETED = "employer_signup_step_completed"
}

// Vitable Event Payloads
export type PageViewedProperties = {
  name: RouteRecordName | null | undefined
  path: string
  from_name: RouteRecordName | null | undefined
  from_path: string | null
}

export type ButtonClickedProperties = {
  page_name: string
  cta_text: string
  button_id?: Optional<string>
}

// App Specific Event Payload
