import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from "vue-router"

export interface GuardianRunnerConfig {
  globalGuardians: Array<NavigationGuard>
}

let _isInit = false
const _globalGuardians: Array<NavigationGuard> = []

function runGuardians(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  guardians: Array<NavigationGuard>
) {
  if (!_isInit) throw Error("GuardianRunner has not been initialized, call `init` first!")

  const localAndGlobalGuardians: Array<NavigationGuard> = [..._globalGuardians, ...guardians]

  for (const guardian of localAndGlobalGuardians) {
    const result = guardian(to, from, next)
    if (!result) return
  }

  return next()
}

function init(config: GuardianRunnerConfig): void {
  if (_isInit) throw Error("GuardianRunner has already been initialized!")

  _globalGuardians.push(...config.globalGuardians)
  _isInit = true
}

export default {
  init,
  runGuardians
}
