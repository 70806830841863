import useAsclepius from "@/composables/api/asclepius"
import { Ref, ref, watch } from "vue"
import { Entitlement, MedicalService } from "@vitable/asclepius-api-client-ts"

export const useGetCompanyEntitlements = () => {
  const { getEntitlementsApi, call, callResult } = useAsclepius()
  const entitlements: Ref<Array<Entitlement>> = ref([])

  async function getCompanyEntitlements(companyId: string) {
    await call(() =>
      getEntitlementsApi().listAllEntitlementsForCompany({
        companyId: companyId
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      entitlements.value = newValue.data.entitlements
    }
  })

  return {
    entitlements,
    getCompanyEntitlements,
    getCompanyEntitlementsResult: callResult
  }
}

export const useGetCompanyEntitledMedicalServices = () => {
  const { getEntitlementsApi, call, callResult } = useAsclepius()
  const medicalServices: Ref<Array<MedicalService>> = ref([])

  async function getCompanyEntitledMedicalServices(companyId: string) {
    await call(() =>
      getEntitlementsApi().listAllEntitledMedicalServicesForCompany({
        companyId: companyId
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      medicalServices.value = newValue.data.medical_services
    }
  })

  return {
    medicalServices,
    getCompanyEntitledMedicalServices,
    getCompanyEntitledMedicalServicesResult: callResult
  }
}
