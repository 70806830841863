import { defineStore } from "pinia"
import { BaseUser, CompanyAdmin, CompanyAdminStatusEnum } from "@vitable/asclepius-api-client-ts"
import router from "@/router"
import eventBus from "@/events/global-event-bus"
import events from "@/modules/login/events/events"
import { useCompanyStore } from "@/store/company-store"
import { useSubscriptionStore } from "@/store/subscription-store"

// @ts-ignore
import { useGetMe } from "@/composables/auth"
import { useWorkforceIntegrationsStore } from "@/modules/workforce-integrations/stores/workforce-integrations-store"

async function _onLogInSuccess() {
  const companyStore = useCompanyStore()
  const subscriptionStore = useSubscriptionStore()
  const workforceIntegrationsStore = useWorkforceIntegrationsStore()

  await companyStore.getCompany()

  await Promise.all([
    subscriptionStore.loadActiveCompanyYouthMembershipPlan(),
    subscriptionStore.loadActiveCompanySubscription(),
    subscriptionStore.loadEntitlements(),
    subscriptionStore.loadBenefits()
  ])

  subscriptionStore.loadMedicalServices()
  subscriptionStore.loadActiveBenefitEligibilityPolicy()
  companyStore.loadEmployeesWithMissingInfoCount()
  workforceIntegrationsStore.loadWorkforceIntegrations()
  eventBus.$emit(events.LOG_IN)
}

export interface UserState {
  user: Nullable<BaseUser>
  token: Nullable<string>
}

export const useUserStore = defineStore("userStore", {
  persist: true,
  state: (): UserState => {
    return {
      token: null,
      user: null
    }
  },
  getters: {
    isLoggedIn(): boolean {
      return !!(this.user && this.token)
    },
    companyAdmin(): Optional<CompanyAdmin> {
      return this.user?.company_admin
    },
    isActive(): boolean {
      return this.companyAdmin?.status === CompanyAdminStatusEnum.Active
    },
    companyId(): string | null | undefined {
      return this.user?.company_admin?.company_id
    },
    status(): Optional<CompanyAdminStatusEnum> {
      return this.user?.company_admin?.status
    },
    userId(): string | null | undefined {
      return this.user?.id
    }
  },
  actions: {
    async init() {
      const userStoreCache: string | null = window.localStorage.getItem("userStore")
      if (!userStoreCache) return

      const store: UserState = JSON.parse(userStoreCache)
      if (store.user && store.token) {
        // Is token valid
        const { getMe, me } = useGetMe()
        await getMe()
        this.setUser(me.value!, store.token)
        await _onLogInSuccess()
      }
    },

    setUser(user: BaseUser, token: Nullable<string>) {
      this.user = user
      if (token) this.token = token
    },
    setCompanyAdmin(companyAdmin: CompanyAdmin) {
      if (this.isLoggedIn) this.user!.company_admin = companyAdmin
    },

    async login(user: BaseUser, token: string, redirectToPath?: Nullable<string>, fromSignUp?: Nullable<boolean>) {
      this.setUser(user, token)
      await _onLogInSuccess()

      if (redirectToPath) {
        window.location.href = redirectToPath
      } else if (fromSignUp) {
        await router.push({ name: "onboarding", query: { redirectedFrom: "signup" } })
      } else {
        await router.push({ name: "home" })
      }
    },

    logout() {
      this.$reset()
      router.push({ name: "login" }).then()
      eventBus.$emit(events.LOG_OUT)
    }
  }
})
