import eventBus from "@/events/global-event-bus"
import memberEvents from "@/modules/member-management/events/events"
import useAsclepius from "@/composables/api/asclepius"
import { reactive, ref, watch } from "vue"
import {
  CompanyMember,
  CompanyMemberApiCreateCompanyEmployeeRequest,
  CompanyMemberApiCreateCompanyRecruitRequest,
  CompanyMemberApiCreateCompanyYouthRequest,
  CompanyMemberApiDeactivateCompanyMemberRequest,
  CompanyMemberApiListCompanyMembersRequest,
  CompanyMemberApiPromoteCompanyRecruitToEmployeeRequest,
  CompanyMemberApiUpdateCompanyMemberRequest,
  CompanyMemberTypeEnum,
  Tag
} from "@vitable/asclepius-api-client-ts"
import { useUserStore } from "@/store/user-store"

export const useGetMember = () => {
  const { getMemberApi, call, callResult } = useAsclepius()

  const member = ref(null)

  async function getMember(memberId: string) {
    await call(() =>
      getMemberApi().retrieveMember({
        memberId
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data = newValue.getData()
      member.value = data.member
    }
  })

  return {
    getMember,
    getMemberResult: callResult,
    member
  }
}

export const useUpdateCompanyMember = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()
  const companyMember = ref<Nullable<CompanyMember>>(null)

  async function updateCompanyMember(request: CompanyMemberApiUpdateCompanyMemberRequest) {
    await call(() => getCompanyMemberApi().updateCompanyMember(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      companyMember.value = newValue.getData().company_member
      eventBus.$emit(memberEvents.MEMBER_UPDATED)
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    updateCompanyMember,
    updateMemberResult: callResult
  }
}

// TODO: @Deprecated -- DO NOT USE, plz see useListCompanyMembers below - a much simpler version
/**
 * Lists all Company Members within the logged-in user's company.
 */
export const useListCompanyPeople = () => {
  const userStore = useUserStore()
  const companyId = userStore.companyId!

  const { getCompanyMemberApi, call, callResult } = useAsclepius()

  const members = reactive({
    currentPage: 1,
    nextPage: 1,
    prevPage: 0,
    totalCount: 0,
    results: []
  })

  type listCompanyMembersParams = {
    email?: Optional<string>
    phone?: Optional<string>
    name?: Optional<string>
    memberId?: Optional<string>
    type?: Optional<CompanyMemberTypeEnum>
    activeIn?: Optional<boolean>
    tags?: Optional<Array<Tag>>
    includeTags?: Optional<boolean>
    limit?: Optional<number>
    missingCriticalEmployeeInfoIn?: Optional<boolean>
  }

  async function listCompanyMembers(
    {
      email,
      phone,
      name,
      memberId,
      type,
      activeIn,
      tags,
      includeTags,
      limit,
      missingCriticalEmployeeInfoIn
    }: listCompanyMembersParams = {
      activeIn: true,
      includeTags: true,
      limit: 20
    }
  ) {
    await call(() =>
      getCompanyMemberApi().listCompanyMembers({
        companyId: companyId,
        type: type,
        activeIn: activeIn,
        memberId: memberId,
        memberEmail: email,
        memberPhone: phone,
        memberName: name,
        tagsIds: tags ? tags.map((i) => i.id) : undefined,
        includeTagsIn: includeTags,
        missingCriticalEmployeeInfoIn: missingCriticalEmployeeInfoIn,
        page: members.currentPage,
        limit: limit
      })
    )
  }

  type searchCompanyMembersParams = {
    searchInput: string
    tags?: Optional<Array<Tag>>
    includeTagsIn: Optional<boolean>
    type?: Optional<CompanyMemberTypeEnum>
    activeIn: Optional<boolean>
    coveredIn: Optional<boolean>
    missingCriticalEmployeeInfoIn: Optional<boolean>
  }

  async function searchCompanyMembers({
    searchInput,
    tags,
    includeTagsIn,
    type,
    activeIn,
    missingCriticalEmployeeInfoIn
  }: searchCompanyMembersParams) {
    const params: listCompanyMembersParams = {}

    if (type) params.type = type
    if (activeIn !== undefined) params.activeIn = activeIn
    if (includeTagsIn !== undefined) params.includeTags = includeTagsIn
    if (tags) params.tags = tags
    if (searchInput && searchInput.trim() !== "") {
      if (searchInput.includes("@")) {
        params.email = searchInput
      } else if (/\d/.test(searchInput)) {
        params.phone = searchInput
      } else {
        params.name = searchInput
      }
    }
    if (missingCriticalEmployeeInfoIn) params.missingCriticalEmployeeInfoIn = missingCriticalEmployeeInfoIn

    await listCompanyMembers(params)
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data = callResult.value.getData()
      members.nextPage = data.next
      members.prevPage = data.previous
      members.totalCount = data.count
      members.results = data.results
    }
  })

  return {
    listCompanyMembers,
    listCompanyMembersResult: callResult,
    searchCompanyMembers,
    members
  }
}

export const useListCompanyMembers = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()

  const companyMembersPaginated = reactive({
    currentPage: 1,
    nextPage: 1,
    prevPage: 0,
    totalCount: 0,
    results: []
  })

  async function listCompanyMembers(request: CompanyMemberApiListCompanyMembersRequest) {
    request = { ...request, page: companyMembersPaginated.currentPage }
    await call(() => getCompanyMemberApi().listCompanyMembers(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      companyMembersPaginated.results = newValue.data.results
      companyMembersPaginated.nextPage = newValue.data.next
      companyMembersPaginated.prevPage = newValue.data.previous
      companyMembersPaginated.totalCount = newValue.data.count
    }
  })

  return {
    listCompanyMembers,
    listCompanyMembersResult: callResult,
    companyMembersPaginated
  }
}

/**
 * Gets an existing Company Member within the logged-in user's company.
 */
export const useGetCompanyMember = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()

  const companyMember = ref<CompanyMember>()

  async function getCompanyMember(companyMemberId: string, { includeTagsIn } = { includeTagsIn: false }) {
    await call(() =>
      getCompanyMemberApi().getCompanyMember({
        companyMemberId: companyMemberId,
        includeTagsIn: includeTagsIn
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data = newValue.getData()
      companyMember.value = data.company_member
    }
  })

  return {
    getCompanyMember,
    getCompanyMemberResult: callResult,
    companyMember
  }
}

/**
 * Create a new Company Employee with logged-in user's Company.
 */
export const useCreateCompanyEmployee = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()
  const createdCompanyEmployee = ref(null)

  async function createCompanyEmployee(request: CompanyMemberApiCreateCompanyEmployeeRequest) {
    await call(() => getCompanyMemberApi().createCompanyEmployee(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data = newValue.getData()
      createdCompanyEmployee.value = data.company_member

      eventBus.$emit(memberEvents.COMPANY_EMPLOYEE_ADDED, data.company_member)
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    createCompanyEmployee,
    createCompanyEmployeeResult: callResult,
    createdCompanyEmployee
  }
}

/**
 * Create a new Company Recruit with logged-in user's Company.
 */
export const useCreateCompanyRecruit = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()
  const createdCompanyRecruit = ref(null)

  async function createCompanyRecruit(request: CompanyMemberApiCreateCompanyRecruitRequest) {
    await call(() => getCompanyMemberApi().createCompanyRecruit(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data = newValue.getData()
      createdCompanyRecruit.value = data.company_member

      eventBus.$emit(memberEvents.COMPANY_RECRUIT_ADDED, data.company_member)
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    createCompanyRecruit,
    createCompanyRecruitResult: callResult,
    createdCompanyRecruit
  }
}

/**
 * Create a new Company Youth
 */
export const useCreateCompanyYouth = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()
  const createdCompanyYouth = ref(null)

  async function createCompanyYouth(request: CompanyMemberApiCreateCompanyYouthRequest) {
    await call(() => getCompanyMemberApi().createCompanyYouth(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data = newValue.getData()
      createdCompanyYouth.value = data.company_member

      eventBus.$emit(memberEvents.COMPANY_YOUTH_ADDED, data.company_member)
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    createCompanyYouth,
    createCompanyYouthResult: callResult,
    createdCompanyYouth
  }
}

/**
 * Reactivates a Company Employee for Company.
 */
export const useReactivateCompanyEmployee = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()

  let lastCompanyMemberId: Nullable<string> = null

  async function reactivateCompanyEmployee(companyMemberId: string) {
    lastCompanyMemberId = companyMemberId
    await call(() =>
      getCompanyMemberApi().reactivateCompanyMember({
        companyMemberId: companyMemberId
      })
    )
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      eventBus.$emit(memberEvents.COMPANY_EMPLOYEE_REACTIVATED, lastCompanyMemberId)
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    reactivateCompanyEmployee,
    reactivateCompanyEmployeeResult: callResult
  }
}

/**
 * Removes a Company Employee from Company.
 */
export const useDeactivateCompanyEmployee = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()

  let lastRemovedCompanyMemberId: Nullable<string> = null

  async function removeCompanyEmployee(request: CompanyMemberApiDeactivateCompanyMemberRequest) {
    lastRemovedCompanyMemberId = request.companyMemberId
    await call(() => getCompanyMemberApi().deactivateCompanyMember(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      if (lastRemovedCompanyMemberId) {
        eventBus.$emit(memberEvents.COMPANY_EMPLOYEE_DEACTIVATED, lastRemovedCompanyMemberId)
      }
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    removeCompanyEmployee,
    removeCompanyEmployeeResult: callResult
  }
}

/**
 * Promotes a Company Recruit to a Company Employee.
 */
export const usePromoteCompanyRecruitToEmployee = () => {
  const { getCompanyMemberApi, call, callResult } = useAsclepius()

  const promotedCompanyMember = ref<CompanyMember>()

  async function promoteCompanyRecruitToEmployee(request: CompanyMemberApiPromoteCompanyRecruitToEmployeeRequest) {
    await call(() => getCompanyMemberApi().promoteCompanyRecruitToEmployee(request))
  }

  watch(callResult, (newValue) => {
    if (newValue.isSuccess()) {
      const data = newValue.getData()
      promotedCompanyMember.value = data.company_member

      eventBus.$emit(memberEvents.COMPANY_EMPLOYEE_ADDED, data.company_member)
      eventBus.$emit(memberEvents.COMPANY_MEMBERS_CHANGED)
    }
  })

  return {
    promoteCompanyRecruitToEmployee,
    promoteCompanyRecruitToEmployeeResult: callResult,
    promotedCompanyMember
  }
}
