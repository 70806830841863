import { ref } from "vue"
import eventBus from "@/events/global-event-bus"
import events from "@/modules/subscription/events/events"
import useAsclepius from "@/composables/api/asclepius"
import { useSubscriptionStore } from "@/store/subscription-store"
import { CompanySubscription, MedicalService } from "@vitable/asclepius-api-client-ts/api"

export const useListCompanySubscriptions = () => {
  const { getBillingApi, call, callResult } = useAsclepius()

  const companySubscriptions = ref([])

  async function listCompanySubscriptions({ companyId, activeIn }: { companyId: string; activeIn: boolean }) {
    await call(() =>
      getBillingApi().listCompanySubscriptions({
        companyId,
        activeIn
      })
    )

    const responseData = callResult.value.getData()
    companySubscriptions.value = responseData.company_subscriptions
  }

  return {
    listCompanySubscriptions,
    listCompanySubscriptionsResult: callResult,
    companySubscriptions
  }
}

export const useGetActiveCompanySubscription = () => {
  const { getBillingApi, call, callResult } = useAsclepius()

  const companySubscription = ref<Nullable<CompanySubscription>>(null)

  async function getActiveCompanySubscription({ companyId }: { companyId: string }) {
    await call(() =>
      getBillingApi().getActiveCompanySubscription({
        companyId
      })
    )

    const responseData = callResult.value.getData()
    companySubscription.value = responseData.company_subscription
  }

  return {
    getActiveCompanySubscription,
    getActiveCompanySubscriptionResult: callResult,
    companySubscription
  }
}

export const useUpgradeNumberOfMemberships = () => {
  const { getBillingApi, call, callResult: upgradeNumberOfMembershipsResult } = useAsclepius()
  const companySubscription = ref(null)

  async function upgradeNumberOfMemberships({
    companySubscriptionId,
    upgradeAmount
  }: {
    companySubscriptionId: string
    upgradeAmount: number
  }) {
    const billingApi = getBillingApi()
    await call(() =>
      billingApi.upgradeCompanySubscriptionContractMemberships({
        companySubscriptionId,
        upgradeCompanySubscriptionContractMembershipsRequest: {
          upgrade_amount: upgradeAmount
        }
      })
    )

    const response = upgradeNumberOfMembershipsResult.value.getData()
    companySubscription.value = response.company_subscription
    eventBus.$emit(events.MEMBERSHIPS_ADDED)
  }

  return {
    upgradeNumberOfMembershipsResult,
    companySubscription,
    upgradeNumberOfMemberships
  }
}

export const useFilterCompanyEligibleServices = () => {
  const subscriptionStore = useSubscriptionStore()
  const activeSubscription: Nullable<CompanySubscription> = subscriptionStore.activeSubscription
  const hasClinicPlanIn = subscriptionStore.hasClinicPlanIn

  function filterEligibleCompanyServices(availableServices: Array<MedicalService>) {
    if (activeSubscription && hasClinicPlanIn && availableServices) {
      const onsiteClinicPlanServicesIdSet = new Set(subscriptionStore.medicalServices.map((s) => s.id))
      return availableServices.filter((s) => onsiteClinicPlanServicesIdSet.has(s.id))
    }
    return []
  }

  return {
    filterEligibleCompanyServices
  }
}
