import { analyticsEvents, ButtonClickedProperties, PageViewedProperties } from "@/services/analytics/analytics-events"
import { useUserStore } from "@/store/user-store"
import { useCompanyStore } from "@/store/company-store"
import { BaseUser } from "@vitable/asclepius-api-client-ts"

function _getDefaultEventProperties() {
  const userStore = useUserStore()
  const companyStore = useCompanyStore()
  return {
    user_id: userStore.user?.id,
    company_name: companyStore.companyName,
    company_id: companyStore.companyId
  }
}

function _isInited() {
  // @ts-ignore
  return freshpaint.track
}

function track(event: string, properties?: any) {
  if (!_isInited()) return

  // @ts-ignore
  freshpaint.track(event, properties)
}

function pageViewed(properties: PageViewedProperties) {
  if (!_isInited()) return

  // @ts-ignore
  freshpaint.track(analyticsEvents.PAGE_VIEWED, properties)
}

function buttonClicked(properties: ButtonClickedProperties) {
  if (!_isInited()) return

  // @ts-ignore
  freshpaint.track(analyticsEvents.BUTTON_CLICKED, properties)
}

function loggedIn(user: BaseUser) {
  if (!useUserStore().isLoggedIn) throw new Error("Must be logged in to call this method!")
  if (!_isInited()) return

  // @ts-ignore
  freshpaint.addEventProperties(_getDefaultEventProperties())
  // @ts-ignore
  freshpaint.identify(user.id, {
    /* user properties */
    name: user.company_admin ? "Company Admin User" : undefined,
    email: user.email,
    user_id: user.id,
    user_type: user.user_type,
    company_admin_id: user.company_admin?.id
  })
  // @ts-ignore
  freshpaint.track(analyticsEvents.LOG_IN)
}

function loggedOut() {
  if (!useUserStore().isLoggedIn) return
  if (!_isInited()) return

  for (const key of Object.keys(_getDefaultEventProperties())) {
    // @ts-ignore
    freshpaint.removeEventProperty(key)
  }
}

export default {
  track,
  pageViewed,
  buttonClicked,
  loggedIn,
  loggedOut
}
