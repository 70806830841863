import { useOccupationalHealthStore } from "@/store/occupational-health-store"

async function handleLogInEvent() {
  await useOccupationalHealthStore().getNewOhrCount()
}

async function handleLogOutEvent() {
  useOccupationalHealthStore().clearOhrCount()
}

export default {
  handleLogInEvent,
  handleLogOutEvent
}
