import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"
import { ensureHasBenefitsGuardian } from "@/router/guardians"

export const routes = [
  {
    path: "/benefits",
    name: "my-benefits",
    component: () => import("@/modules/benefits/pages/my-benefits/MyBenefitsPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasBenefitsGuardian
      ])
    }
  },
  {
    path: "/benefits/:companyBenefitId",
    name: "benefit-summary",
    component: () => import("@/modules/benefits/pages/benefit-summary/BenefitSummary.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasBenefitsGuardian
      ])
    },
    props: true
  }
]
