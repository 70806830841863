import { ref } from "vue"
import useAsclepius from "@/composables/api/asclepius"
import {
  AppNameEnum,
  BaseUser,
  UserTypeEnum,
  VitableStandardAuthenticationRequest,
  VitableStandardAuthenticationResponse
} from "@vitable/asclepius-api-client-ts"
import { version } from "../../package.json"
import { useUserStore } from "@/store/user-store"
import { analyticsEvents } from "@/services/analytics/analytics-events"

/**
 * Gets the current authenticated user into the Company Dashboard.
 */
export const useGetMe = () => {
  const { getAuthenticationApi, call, callResult } = useAsclepius()

  const me = ref<BaseUser | null>(null)

  async function getMe() {
    await call(() => getAuthenticationApi().getMe({}))
    me.value = callResult.value.getData().me
  }

  return {
    getMe,
    getMeResult: callResult,
    me
  }
}

/**
 * Performs authentication of the user with a password.
 */
export function useLogin() {
  const { getAuthenticationApi, call, callResult } = useAsclepius()
  const userStore = useUserStore()
  const loginInProgress = ref(false)

  type LoginParams = {
    emailOrPhone: string
    password: string
    redirectToPath?: Nullable<string>
    fromSignUp?: Nullable<boolean>
  }

  async function login(params: LoginParams): Promise<void> {
    const request: VitableStandardAuthenticationRequest = {
      email_or_phone: params.emailOrPhone,
      user_type: UserTypeEnum.CompanyAdmin,
      password: params.password,
      app_name: AppNameEnum.CompanyDashboard,
      app_version: version
    }

    loginInProgress.value = true
    try {
      await call(
        async () =>
          await getAuthenticationApi().authenticateWithStandard({ vitableStandardAuthenticationRequest: request })
      )
      if (callResult.value.isSuccess()) {
        const data: VitableStandardAuthenticationResponse = callResult.value.getData()
        await userStore.login(data.user!, data.token!, params.redirectToPath, params.fromSignUp)
        // @ts-ignore
        freshpaint.track(analyticsEvents.LOG_IN, { email: data.user!.email, phone: data.user!.phone })
      }
    } catch (e) {
      loginInProgress.value = false
    }
    loginInProgress.value = false
  }

  return {
    login,
    loginResult: callResult,
    loginInProgress
  }
}

export type LoginPasswordlessParams = {
  emailOrPhone: string
  userType: UserTypeEnum
  passwordlessToken: Nullable<string>
}

export const useLoginPasswordless = () => {
  const { getAuthenticationApi, call, callResult } = useAsclepius()

  async function loginPasswordless(params: LoginPasswordlessParams) {
    await call(() =>
      getAuthenticationApi().authenticateWithPasswordless({
        vitablePasswordlessAuthenticationRequest: {
          email_or_phone: params.emailOrPhone,
          user_type: params.userType,
          token: params.passwordlessToken,
          app_name: AppNameEnum.CompanyDashboard,
          app_version: version
        }
      })
    )
  }

  return {
    loginPasswordless,
    loginPasswordlessResult: callResult
  }
}

export const useUpdatePassword = () => {
  const { getUserApi, call, callResult } = useAsclepius()

  async function updatePassword(newPassword: string) {
    await call(() =>
      getUserApi().updatePassword({
        updatePasswordRequest: {
          new_password: newPassword
        }
      })
    )
  }

  return {
    updatePassword,
    updatePasswordResult: callResult
  }
}
