import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"
import { ensureHasYouthClinicsFeature } from "@/router/guardians"

export const dashboardRoutes = [
  {
    path: "/dashboard/members/employees",
    name: "employees",
    component: () => import("@/modules/member-management/pages/EmployeesPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
    }
  },
  {
    path: "/dashboard/members/youth",
    name: "youth",
    component: () => import("@/modules/member-management/pages/YouthPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasYouthClinicsFeature
      ])
    }
  },
  {
    path: "/dashboard/members/recruits",
    name: "recruits",
    component: () => import("@/modules/member-management/pages/RecruitsPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
    }
  },
  {
    path: "/dashboard/members/:companyMemberId",
    name: "member-details",
    component: () => import("@/modules/member-management/pages/member-details/CompanyMemberDetailsPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
    },
    props: true
  }
]
