import moment, { Moment } from "moment"
import { capitalize } from "./utils"
import { Address } from "@vitable/asclepius-api-client-ts"

export function simpleDate(date: Moment | Date | string) {
  return moment(date).format("dddd MMM DD")
}

export function date(date: Moment | Date | string) {
  return moment(date).format("MM/DD/YYYY")
}

export function time(time: Moment | Date | string) {
  return moment(time).format("h:mm A")
}

export function timestamp(timestamp: Moment | Date | string) {
  return date(timestamp) + " at " + time(timestamp)
}

export function shortTimestamp(timestamp: Moment | Date | string) {
  return date(timestamp) + ", " + time(timestamp)
}

export function timeRange([start, end]: [Moment | Date | string, Moment | Date | string]) {
  return `between ${time(start)} and ${time(end)}`
}

export function timestampRange([start, end]: [Moment | Date | string, Moment | Date | string]) {
  return `between ${timestamp(start)} and ${timestamp(end)}`
}

export function shortTimestampRange([start, end]: [Moment | Date | string, Moment | Date | string]) {
  return `${shortTimestamp(start)} - ${shortTimestamp(end)}`
}

export function shortTimeRange([start, end]: [Moment | Date | string, Moment | Date | string]) {
  return `${time(start)} - ${time(end)}`
}

export function price(cents: number) {
  return "$" + (cents / 100).toFixed(cents % 100 && 2)
}

export function healthRecordType(ohr: any) {
  if (!ohr) {
    return "All Types"
  }

  return ohrType(ohr.type)
}

export function ohrType(ohrType: string) {
  const type = ohrType.replaceAll("_", " ")
  return type
    .split(" ")
    .map((w) => capitalize(w))
    .join(" ")
}

export function longDate(date: Moment | Date | string) {
  return moment(date).format("ddd, MMM DD, YYYY")
}

export function longDateAndTime(date: Moment | Date | string) {
  return moment(date).format("ddd, MMM DD, YYYY @ h:mm A")
}

export function toAddressString(address: Address) {
  const addressLine1 = address.address_line_1
  const addressLine2 = address.address_line_2
  const city = address.city
  const state = address.state
  const zipCode = address.zipcode
  if (!addressLine1 || !city || !state || !zipCode) {
    return "Invalid address format!"
  } else if (addressLine2) {
    return `${addressLine1}, ${addressLine2}, ${city}, ${state}, ${zipCode}`
  }
  return `${addressLine1}, ${city}, ${state}, ${zipCode}`
}

export default {
  date,
  healthRecordType,
  longDate,
  longDateAndTime,
  ohrType,
  price,
  simpleDate,
  time,
  timestamp
}
