import analyticsService from "@/services/analytics/analytics-service"

const trackClick = {
  mounted(el: HTMLElement, binding: any) {
    el.addEventListener("click", () => {
      analyticsService.buttonClicked({
        page_name: binding.instance.$route?.name,
        cta_text: binding.value,
        button_id: el.id
      })
    })
  }
}

export default trackClick
