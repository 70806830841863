import { ensureHasBenefitsGuardian, ensureHasLabOrderingFeatureGuardian } from "@/router/guardians"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"

export const dashboardRoutes = [
  {
    path: "/reporting/occupational-health/lab-orders",
    name: "occupational-health-lab-orders-report",
    component: () => import("@/modules/reporting/pages/OccupationalHealthLabOrdersReport.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasLabOrderingFeatureGuardian
      ])
    }
  },
  {
    path: "/reporting/employee-engagement",
    name: "employee-engagement-report",
    component: () => import("@/modules/reporting/pages/EmployeeEngagementReport.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasBenefitsGuardian
      ])
    }
  }
]
