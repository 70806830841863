import memberManagementModuleEvents from "@/modules/member-management/events/events"
import memberManagementEventHandlers from "@/modules/member-management/events/event-handlers"
import { EventRegister } from "@/events/event-register"

export const registerEventListeners: EventRegister = (eventBus) => {
  eventBus.$on(
    memberManagementModuleEvents.COMPANY_MEMBERS_CHANGED,
    memberManagementEventHandlers.handleCompanyMembersChangedEvent
  )
}
