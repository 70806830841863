import { defineStore } from "pinia"
import moment from "moment"
import { useListOccupationalHealthRecords } from "@/modules/occupational-health/composables/occupational-health-record"

export interface OccupationalHealthState {
  newOhrCount: number
}

export const useOccupationalHealthStore = defineStore("occupationalHealthStore", {
  persist: true,
  state: (): OccupationalHealthState => {
    return {
      newOhrCount: 0
    }
  },

  actions: {
    async getNewOhrCount() {
      const { count, listOhrs } = useListOccupationalHealthRecords()

      const date = moment().subtract(7, "days").toISOString().split("T")[0]

      await listOhrs({ completedOnDateGte: date })
      this.newOhrCount = count.value ?? 0
    },

    clearOhrCount() {
      this.newOhrCount = 0
    }
  }
})
