export type EventHandler = (...args: any[]) => any

export type GlobalEventBus = {
  $on: (eventName: string, fn: EventHandler) => void
  $off: (eventName: string, fn: EventHandler) => void
  $emit: (eventName: string, data?: any) => void
}

function createEventBus(): GlobalEventBus {
  const eventHandlers: Map<string, Array<EventHandler>> = new Map()

  const $on = (eventName: string, fn: EventHandler) => {
    if (!eventHandlers.has(eventName)) eventHandlers.set(eventName, [])

    eventHandlers.get(eventName)!.push(fn)
  }

  const $off = (eventName: string, fn: EventHandler) => {
    if (!eventHandlers.has(eventName)) return

    for (let i = 0; i < eventHandlers.get(eventName)!.length; i++) {
      if (eventHandlers.get(eventName)![i] !== fn) continue

      eventHandlers.get(eventName)!.splice(i, 1)
    }
  }

  const $emit = (eventName: string, data?: any) => {
    if (!eventHandlers.has(eventName)) return

    eventHandlers.get(eventName)!.forEach((fn) => fn(data))
  }

  return {
    $on,
    $off,
    $emit
  }
}

const eventBus: GlobalEventBus = createEventBus()

export default eventBus
