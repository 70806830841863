import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"

export const dashboardRoutes = [
  {
    path: "/dashboard/tags",
    name: "tags",
    component: () => import("@/modules/tag-management/pages/TagManagementPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
    }
  }
]
