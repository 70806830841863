<template>
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <div>
        <component :is="Component" />
      </div>
    </transition>
  </router-view>
</template>

<script>
export default {}
</script>

<style lang="sass">
@import "styles/index.scss"
</style>
