import { useWorkforceIntegrationsStore } from "@/modules/workforce-integrations/stores/workforce-integrations-store"

async function handleLogInEvent() {
  console.log("Workforce module received login event...")
  const workforceIntegrationsStore = useWorkforceIntegrationsStore()
  workforceIntegrationsStore.loadRecentEventCounts()
}

async function handleLogOutEvent() {
  console.log("Workforce module received logout event...")
}

export default {
  handleLogInEvent,
  handleLogOutEvent
}
