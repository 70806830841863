import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"
import { ensureHasClinicsFeature } from "@/router/guardians"

export const dashboardRoutes = [
  {
    path: "/dashboard/clinics",
    name: "clinics",
    component: () => import("@/modules/clinic-history/pages/ClinicHistory.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasClinicsFeature
      ])
    },
    props: (route) => ({ clinicId: route.query.clinicId })
  },
  {
    path: "/dashboard/clinics/:clinicId",
    name: "clinic-details",
    component: () => import("@/modules/clinic-history/pages/ClinicDetails.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasClinicsFeature
      ])
    },
    props: true
  }
]
