/*
    Global Guardians are a special type of Guardian that will run for
    every single route change in the app.

    We place them here for easier organization and provide
    a method to get all of them.
 */

import { NavigationGuard } from "vue-router"

function getGlobalGuardians(): Array<NavigationGuard> {
  return []
}

export default {
  getGlobalGuardians
}
