export const routes = [
  {
    path: "/company",
    name: "company",
    component: () => import("../layouts/CustomPagesDashboard.vue"),
    children: [
      {
        path: "/company/employee-benefits/:id",
        name: "employee-benefits",
        props: true,
        component: () =>
          import("@/modules/company-custom-pages/pages/employee-benefits-overview/EmployeeBenefitsOverview.vue")
      }
    ]
  }
]
