import { defineStore } from "pinia"
import { Company } from "@vitable/asclepius-api-client-ts"
import { useUserStore } from "@/store/user-store"
// @ts-ignore
import { useGetCompany } from "@/composables/company"
import { useListCompanyPeople } from "@/modules/member-management/composables/member"

export interface CompanyState {
  company: Nullable<Company>
  employeesWithCriticalMissingInfoCount: number
}

export const useCompanyStore = defineStore("companyStore", {
  persist: true,
  state: (): CompanyState => {
    return {
      company: null,
      employeesWithCriticalMissingInfoCount: 0
    }
  },
  getters: {
    companyName(): Nullable<string> {
      return this.company?.name ?? null
    },
    companyId(): Nullable<string> {
      return this.company?.id ?? null
    },
    publicCompanyId(): Nullable<string> {
      return this.company?.public_company_id ?? null
    },
    allowedToUpgradePlanIn(): boolean {
      return !!this.company?.allow_auto_plan_upgrade_in
    },
    companyOnboardedIn(): boolean {
      return !!this.company?.onboarded_in
    }
  },
  actions: {
    async getCompany() {
      const userStore = useUserStore()
      const { getCompany, company } = useGetCompany()

      await getCompany(userStore.companyId!)
      this.company = company.value
    },

    async updateCompany(company: Nullable<Company>) {
      this.company = company
    },

    async loadEmployeesWithMissingInfoCount() {
      const { listCompanyMembers, members } = useListCompanyPeople()
      await listCompanyMembers({ missingCriticalEmployeeInfoIn: true })
      this.employeesWithCriticalMissingInfoCount = members.totalCount
    }
  }
})
