import eventBus, { GlobalEventBus } from "@/events/global-event-bus"

export type ModuleRegisterContext = {
  eventBus: GlobalEventBus
}

export type ModuleRegisterFn = (context: ModuleRegisterContext) => void

const registerModule = (moduleRegister: ModuleRegisterFn) => {
  const sharedContext: ModuleRegisterContext = {
    eventBus: eventBus
  }

  moduleRegister(sharedContext)
}

export default registerModule
