export const routes = [
  // TODO: Deprecated for now
  // {
  //   path: "/signup",
  //   name: "signup-layout",
  //   component: () => import("@/modules/signup/layout/SignupLayout.vue"),
  //   children: [
  //     {
  //       path: "/signup",
  //       name: "signup",
  //       component: () => import("@/modules/signup/pages/signup/Signup.vue"),
  //       meta: { requiresAnonymous: true }
  //     }
  //   ]
  // }
]
