import {
  AppointmentApi,
  AuthenticationApi,
  AvailabilityApi,
  BenefitsApi,
  BillingApi,
  CensusApi,
  ClinicalAppointmentApi,
  CompanyAdminApi,
  CompanyApi,
  CompanyMemberApi,
  Configuration,
  EntitlementApi,
  LabOrderingApi,
  MedicalApi,
  MemberApi,
  OnsiteClinicApi,
  SurveyApi,
  TagApi,
  UrgentCareOrderingApi,
  UserApi,
  WorkforceIntegrationApi,
  YouthMembershipApi
} from "@vitable/asclepius-api-client-ts"
import { SERVER_URL } from "@/config"
import { useStateFlow } from "@/composables/api/state-flow"
import { useUserStore } from "@/store/user-store"

export default function useAsclepius() {
  const userStore = useUserStore()
  let _apiClientConfig: Nullable<Configuration> = null

  /**
   * Lazily-loads and prepares asclepius client configuration.
   * @private
   */
  function _getApiClientConfig() {
    const token = userStore.token

    if (_apiClientConfig && token === _apiClientConfig.baseOptions.headers.Authorization) return _apiClientConfig

    if (token) {
      _apiClientConfig = new Configuration({
        basePath: SERVER_URL,
        baseOptions: {
          headers: {
            Authorization: `JWT ${token}`
          }
        }
      })
      return _apiClientConfig
    }

    return new Configuration({ basePath: SERVER_URL })
  }

  const getAuthenticationApi = () => new AuthenticationApi(_getApiClientConfig())
  const getCensusApi = () => new CensusApi(_getApiClientConfig())
  const getCompanyApi = () => new CompanyApi(_getApiClientConfig())
  const getCompanyMemberApi = () => new CompanyMemberApi(_getApiClientConfig())
  const getMemberApi = () => new MemberApi(_getApiClientConfig())
  const getBillingApi = () => new BillingApi(_getApiClientConfig())
  const getMedicalApi = () => new MedicalApi(_getApiClientConfig())
  const getAppointmentApi = () => new AppointmentApi(_getApiClientConfig())
  const getAvailabilityApi = () => new AvailabilityApi(_getApiClientConfig())
  const getClinicalAppointmentApi = () => new ClinicalAppointmentApi(_getApiClientConfig())
  const getCompanyAdminApi = () => new CompanyAdminApi(_getApiClientConfig())
  const getLabOrderingApi = () => new LabOrderingApi(_getApiClientConfig())
  const getOnsiteClinicApi = () => new OnsiteClinicApi(_getApiClientConfig())
  const getSurveyApi = () => new SurveyApi(_getApiClientConfig())
  const getUrgentCareOrderingApi = () => new UrgentCareOrderingApi(_getApiClientConfig())
  const getTagApi = () => new TagApi(_getApiClientConfig())
  const getBenefitsApi = () => new BenefitsApi(_getApiClientConfig())
  const getEntitlementsApi = () => new EntitlementApi(_getApiClientConfig())
  const getUserApi = () => new UserApi(_getApiClientConfig())
  const getWorkforceIntegrationApi = () => new WorkforceIntegrationApi(_getApiClientConfig())
  const getYouthMembershipApi = () => new YouthMembershipApi(_getApiClientConfig())

  const { result, setLoadingResult, setFailureResult, setSuccessResult } = useStateFlow()

  async function call(requestPromise: () => Promise<any>) {
    setLoadingResult()
    try {
      setSuccessResult((await requestPromise()).data)
    } catch (e: any) {
      console.error(e)

      const errorPayloadData = e.response?.data

      // intercept 401 and logout
      if (e.response?.status === 401 || errorPayloadData?.code === "token_not_valid") {
        userStore.logout()
      }

      if (errorPayloadData) {
        const asclepiusError = new AsclepiusError(
          e,
          errorPayloadData.error,
          errorPayloadData.status,
          errorPayloadData.status_code
        )
        setFailureResult(asclepiusError, false)
        throw asclepiusError
      }

      const error = new Error("An unexpected error occurred, please try again later")
      setFailureResult(error, false)
      throw error
    }
  }

  async function callReturn<T>(requestPromise: () => Promise<any>): Promise<T> {
    setLoadingResult()
    try {
      const data: T = (await requestPromise()).data
      setSuccessResult(data)
      return data
    } catch (e: any) {
      console.error(e)

      const errorPayloadData = e.response?.data

      // intercept 401 and logout
      if (e.response?.status === 401 || errorPayloadData?.code === "token_not_valid") {
        userStore.logout()
      }

      if (errorPayloadData) {
        const asclepiusError = new AsclepiusError(
          e,
          errorPayloadData.error,
          errorPayloadData.status,
          errorPayloadData.status_code
        )
        setFailureResult(asclepiusError, false)
        throw asclepiusError
      }

      const error = new Error("An unexpected error occurred, please try again later")
      setFailureResult(error, false)
      throw error
    }
  }

  return {
    call,
    callReturn,
    callResult: result,
    getAuthenticationApi,
    getCensusApi,
    getCompanyApi,
    getCompanyMemberApi,
    getMemberApi,
    getBillingApi,
    getMedicalApi,
    getAppointmentApi,
    getAvailabilityApi,
    getClinicalAppointmentApi,
    getCompanyAdminApi,
    getLabOrderingApi,
    getOnsiteClinicApi,
    getSurveyApi,
    getUrgentCareOrderingApi,
    getTagApi,
    getBenefitsApi,
    getEntitlementsApi,
    getUserApi,
    getWorkforceIntegrationApi,
    getYouthMembershipApi
  }
}

/**
 * Specialization of *Error*.
 * Contains normalized asclepius error response.
 */
export class AsclepiusError extends Error {
  public status: Nullable<number>
  public statusCode: Nullable<string>

  constructor(cause: any, message: any, status: Nullable<number>, statusCode: Nullable<string>) {
    super(message)
    this.cause = cause
    this.status = status
    this.statusCode = statusCode
  }
}
