import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"
import { ensureHasOccHealthFeature, ensureHasServiceRequestingFeature, ensureIsAnonymous } from "@/router/guardians"

export const dashboardRoutes = [
  {
    path: "/dashboard/occupational-health/compliance-tracking",
    name: "occupational-health-compliance-tracking",
    component: () => import("@/modules/occupational-health/pages/ComplianceTracking.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasOccHealthFeature
      ])
    }
  },
  {
    path: "/dashboard/occupational-health/records",
    name: "occupational-health-records",
    component: () => import("@/modules/occupational-health/pages/OccupationalHealthRecords.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasOccHealthFeature
      ])
    }
  },
  {
    path: "/dashboard/occupational-health/records/:id",
    name: "occupational-health-record-details",
    props: true,
    component: () =>
      import(
        "@/modules/occupational-health/pages/occupational-health-record-details-page/OccupationalHealthRecordDetailsPage.vue"
      ),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasOccHealthFeature
      ])
    }
  },
  {
    path: "/dashboard/occupational-health/lab-order-requests",
    name: "occupational-health-lab-order-requests",
    component: () => import("@/modules/occupational-health/pages/order-requests-page/OrderRequestsPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasServiceRequestingFeature
      ])
    },
    props: true
  }
]

export const routes = [
  {
    path: "/request-a-lab/:publicCompanyId",
    name: "occupational-health-lab-order-requesting",
    component: () => import("@/modules/occupational-health/pages/RequestOccupationalHealthLabOrderPage.vue"),
    props: true
  },
  {
    path: "/occupational-health-lab-order-instructions/:occupationalHealthLabOrderRequestId",
    name: "occupational-health-lab-order-instructions",
    component: () =>
      import(
        "@/modules/occupational-health/pages/occupational-health-lab-order-instructions-page/OccupationalHealthLabOrderInstructionsPage.vue"
      ),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
    },
    props: true
  },
  {
    path: "/urgent-care-order-instructions/:urgentCareOrderId",
    name: "urgent-care-order-instructions",
    component: () => import("@/modules/occupational-health/pages/UrgentCareOrderInstructionsPage.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
    },
    props: true
  }
]
