import { ref } from "vue"

enum ResultState {
  NIL = 0,
  LOADING = 1,
  FAILURE = 2,
  SUCCESS = 3
}

type ImmutableResult = {
  data: Nullable<any>
  error: {
    ex: Nullable<Error>
    message: Nullable<string>
  }
  state: ResultState
  isNil: () => boolean
  isLoading: () => boolean
  isFailure: () => boolean
  isSuccess: () => boolean
  isCompleted: () => boolean

  getData: () => Nullable<any>
  getError: () => Nullable<Error>
  getErrorMessage: () => Nullable<string>
}

export const useStateFlow = () => {
  function immutableResult(
    data: Nullable<object>,
    ex: Nullable<Error>,
    exMsg: Nullable<string>,
    state: ResultState
  ): ImmutableResult {
    const obj: ImmutableResult = {
      data: data,
      error: {
        ex: ex,
        message: exMsg
      },
      state: state,

      isNil: () => ResultState.NIL === state,
      isLoading: () => ResultState.LOADING === state,
      isFailure: () => ResultState.FAILURE === state,
      isSuccess: () => ResultState.SUCCESS === state,
      isCompleted: () => ResultState.FAILURE === state || ResultState.SUCCESS === state,

      getData() {
        if (!this.isSuccess()) throw new Error("Not in a successful state!")
        return this.data
      },

      getError() {
        if (!this.isFailure()) return null
        return this.error.ex
      },

      getErrorMessage() {
        if (!this.isFailure()) return null
        return this.error.message
      }
    }
    return Object.freeze(obj)
  }

  // Backing storage for the data result
  const result = ref(immutableResult(null, null, null, ResultState.NIL))

  /**
   * Changes the state of this result back to initial.
   */
  function resetResult() {
    result.value = immutableResult(null, null, null, ResultState.NIL)
  }

  /**
   * Changes the state of this result to be loading.
   */
  function setLoadingResult() {
    result.value = immutableResult(null, null, null, ResultState.LOADING)
  }

  /**
   * Changes the state of this result to be failure.
   * @param ex the exception cause for failure
   * @param logError
   */
  // @ts-ignore
  function setFailureResult(ex, logError = true) {
    if (logError) {
      console.error(ex)
    }
    const localizedMessage = ex.message ? ex.message : "An unexpected error occurred, please try again!"
    result.value = immutableResult(null, ex, localizedMessage, ResultState.FAILURE)
  }

  /**
   * Changes the state of this result to be successful.
   * @param data the data of the result
   */
  function setSuccessResult(data: Nullable<any>) {
    result.value = immutableResult(data, null, null, ResultState.SUCCESS)
  }

  return {
    result,
    resetResult,
    setLoadingResult,
    setFailureResult,
    setSuccessResult
  }
}
