enum events {
  MEMBER_UPDATED = "member-updated",
  MEMBER_TAG_ADDED = "member-tag-added",
  MEMBER_TAG_REMOVED = "member-tag-removed",
  COMPANY_EMPLOYEE_ADDED = "company-employee-added",
  COMPANY_EMPLOYEE_DEACTIVATED = "company-employee-deactivated",
  COMPANY_EMPLOYEE_REACTIVATED = "company-employee-reactivated",
  COMPANY_RECRUIT_ADDED = "company-recruit-added",
  COMPANY_YOUTH_ADDED = "company-youth-added",
  COMPANY_MEMBERS_CHANGED = "company-members-changed"
}

export default events
