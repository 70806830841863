import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"

export const routes = [
  {
    path: "/dashboard/workforce-integrations",
    name: "my-workforce-integrations",
    component: () => import("@/modules/workforce-integrations/pages/MyWorkforceIntegrations.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
    }
  }
]
