import useAsclepius from "@/composables/api/asclepius"
import { ref, watch } from "vue"
import { useCompanyStore } from "@/store/company-store"
import { Address, Company } from "@vitable/asclepius-api-client-ts"

export const useGetCompany = () => {
  const { getCompanyApi, call, callResult } = useAsclepius()

  const company = ref(null)

  async function getCompany(companyId: string) {
    await call(() =>
      getCompanyApi().getCompany({
        companyId
      })
    )
    company.value = callResult.value.getData().company
  }

  return {
    company,
    getCompany,
    getCompanyResult: callResult
  }
}

export const useGetPublicCompanyDetails = () => {
  const { getCompanyApi, call, callResult } = useAsclepius()

  const companyDetails = ref(null)

  async function getPublicCompanyDetails(publicCompanyId: number) {
    await call(() =>
      getCompanyApi().getPublicCompanyDetails({
        publicCompanyId
      })
    )
  }

  watch(callResult, () => (companyDetails.value = callResult.value.isSuccess() ? callResult.value.getData() : null))

  return {
    getPublicCompanyDetails,
    getPublicCompanyDetailsResult: callResult,
    companyDetails
  }
}

export const useUpdateCompanyMaintainedDetails = () => {
  const { getCompanyApi, call, callResult } = useAsclepius()
  const companyStore = useCompanyStore()

  const company = ref<Nullable<Company>>(null)

  async function updateCompanyMaintainedDetails({
    name,
    email,
    address,
    description,
    careersPageLink
  }: {
    name?: Nullable<string>
    email?: Nullable<string>
    address?: Nullable<Address>
    description?: Nullable<string>
    careersPageLink?: Nullable<string>
  }) {
    await call(() =>
      getCompanyApi().updateCompanyMaintainedFields({
        companyId: companyStore.companyId!,
        updateCompanyMaintainedFieldsRequest: {
          name,
          email,
          address,
          description,
          careers_page_link: careersPageLink
        }
      })
    )
  }

  watch(callResult, () => {
    company.value = callResult.value.isSuccess() ? callResult.value.getData().company : null
    companyStore.updateCompany(company.value).then()
  })

  return {
    updateCompanyMaintainedDetails,
    updateCompanyMaintainedDetailsResult: callResult,
    company
  }
}
