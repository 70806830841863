import { createApp, h } from "vue"
import ElementPlus from "element-plus"
import Vue3ColorPicker from "vue3-colorpicker"
import "vue3-colorpicker/style.css"
import router from "./router"
import store from "@/store"
import { useUserStore } from "@/store/user-store"
import App from "./App.vue"
import filters from "./utils/humanize"
import sentryService from "@/services/sentry-service"
import trackClick from "@/services/analytics/directives/track-click"
import "./styles/index.scss"

import registerModule from "@/module-register"
import clinicCheckinModuleRegister from "@/modules/clinic-checkin"
import clinicHistoryModuleRegister from "@/modules/clinic-history"
import clinicSchedulingModuleRegister from "@/modules/clinic-scheduling"
import companyCustomPagesModuleRegister from "@/modules/company-custom-pages"
import loginModuleRegister from "@/modules/login"
import memberManagementModuleRegister from "@/modules/member-management"
import occupationalHealthModuleRegister from "@/modules/occupational-health"
import onboardingModuleRegister from "@/modules/onboarding"
import settingsModuleRegister from "@/modules/settings"
import setupModuleRegister from "@/modules/setup"
import signupModuleRegister from "@/modules/signup"
import subscriptionModuleRegister from "@/modules/subscription"
import tagManagementModuleRegister from "@/modules/tag-management"
import benefitsModule from "@/modules/benefits"
import workforceIntegrationsModule from "@/modules/workforce-integrations"

const app = createApp({
  async created() {
    await useUserStore().init()
  },
  render: () => h(App)
})

// * Add Directives
app.directive("track-click", trackClick)

// * Add filters
app.config.globalProperties.$filters = filters

// register modules
registerModule(clinicCheckinModuleRegister)
registerModule(clinicHistoryModuleRegister)
registerModule(clinicSchedulingModuleRegister)
registerModule(companyCustomPagesModuleRegister)
registerModule(loginModuleRegister)
registerModule(memberManagementModuleRegister)
registerModule(occupationalHealthModuleRegister)
registerModule(onboardingModuleRegister)
registerModule(settingsModuleRegister)
registerModule(setupModuleRegister)
registerModule(signupModuleRegister)
registerModule(subscriptionModuleRegister)
registerModule(tagManagementModuleRegister)
registerModule(benefitsModule)
registerModule(workforceIntegrationsModule)

sentryService.init(app)

// * Add libraries
app.use(router).use(store).use(ElementPlus).use(Vue3ColorPicker)

app.mount("#app")
