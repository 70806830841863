import { dashboardRoutes as memberManagementDashboardRoutes } from "@/modules/member-management/router/routes"
import { dashboardRoutes as occupationalHealthDashboardRoutes } from "@/modules/occupational-health/router/routes"
import { dashboardRoutes as clinicSchedulingDashboardRoutes } from "@/modules/clinic-scheduling/router/routes"
import { dashboardRoutes as clinicHistoryDashboardRoutes } from "@/modules/clinic-history/router/routes"
import { dashboardRoutes as tagManagementDashboardRoutes } from "@/modules/tag-management/router/routes"
import { dashboardRoutes as settingsDashboardRoutes } from "@/modules/settings/router/routes"
import { dashboardRoutes as reportDashboardsDashboardRoutes } from "@/modules/reporting/router/routes"
import { routes as benefitsRoutes } from "@/modules/benefits/router/routes"
import { routes as workforceIntegrationRoutes } from "@/modules/workforce-integrations/router/routes"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"

export const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "dashboard-layout",
    component: () => import("../../layouts/dashboard/DashboardLayout.vue"),
    children: [
      {
        path: "/dashboard/home",
        name: "home",
        component: () => import("../../pages/home/Home.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
        }
      },
      {
        path: "/dashboard/marketplace",
        name: "marketplace",
        component: () => import("../../pages/benefits-marketplace/BenefitsMarketplace.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
        }
      },
      {
        path: "/dashboard/employer-resources",
        name: "employer-resources",
        component: () => import("../../pages/employer-resources/EmployerResources.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [...getGuardianChainForEnsuringAuthOnboardedAndActive()])
        }
      },
      ...memberManagementDashboardRoutes,
      ...occupationalHealthDashboardRoutes,
      ...clinicSchedulingDashboardRoutes,
      ...clinicHistoryDashboardRoutes,
      ...tagManagementDashboardRoutes,
      ...settingsDashboardRoutes,
      ...reportDashboardsDashboardRoutes,
      ...benefitsRoutes,
      ...workforceIntegrationRoutes
    ]
  }
]
