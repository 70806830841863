import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { getGuardianChainForEnsuringAuthOnboardedAndActive } from "@/router/guardian-utils"
import { ensureHasClinicsFeature } from "@/router/guardians"

export const dashboardRoutes = [
  {
    path: "/dashboard/clinics/schedule",
    name: "clinics-schedule",
    component: () => import("@/modules/clinic-scheduling/pages/ScheduleClinic.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasClinicsFeature
      ])
    }
  },
  {
    path: "/dashboard/clinics/bookable",
    name: "clinics-bookable",
    component: () => import("@/modules/clinic-scheduling/pages/BookableClinics.vue"),
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      GuardianRunner.runGuardians(to, from, next, [
        ...getGuardianChainForEnsuringAuthOnboardedAndActive(),
        ensureHasClinicsFeature
      ])
    }
  }
]
