import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import { ensureIsAuthenticated, ensureIsCompanyNotOnboarded } from "@/router/guardians"

export const routes = [
  {
    path: "/onboarding",
    name: "onboarding-layout",
    component: () => import("@/modules/onboarding/layout/OnboardingLayout.vue"),
    children: [
      {
        path: "/onboarding",
        name: "onboarding",
        component: () => import("@/modules/onboarding/pages/onboarding/Onboarding.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [ensureIsAuthenticated, ensureIsCompanyNotOnboarded])
        }
      }
    ]
  }
]
