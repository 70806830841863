import useAsclepius from "@/composables/api/asclepius"
import {
  ListWorkforceIntegrationsResponse,
  ListWorkforceIntegrationSyncOperationEventsResponse,
  RegisterWorkforceIntegrationResponse,
  WorkforceIntegration,
  WorkforceIntegrationApiListWorkforceIntegrationsRequest,
  WorkforceIntegrationApiListWorkforceIntegrationSyncOperationEventsRequest,
  WorkforceIntegrationApiRegisterWorkforceIntegrationRequest,
  WorkforceIntegrationSyncOperationEvent
} from "@vitable/asclepius-api-client-ts"
import { reactive, ref, Ref } from "vue"

export function useRegisterWorkforceIntegration() {
  const { getWorkforceIntegrationApi, callReturn, callResult } = useAsclepius()

  const integration: Ref<Nullable<WorkforceIntegration>> = ref(null)

  async function registerIntegration(request: WorkforceIntegrationApiRegisterWorkforceIntegrationRequest) {
    const data: RegisterWorkforceIntegrationResponse = await callReturn(() =>
      getWorkforceIntegrationApi().registerWorkforceIntegration(request)
    )
    integration.value = data.integration
  }

  return {
    registerIntegration,
    registerIntegrationResult: callResult,
    integration
  }
}

export function useListWorkforceIntegrations() {
  const { getWorkforceIntegrationApi, callReturn, callResult } = useAsclepius()

  const integrations: Ref<Array<WorkforceIntegration>> = ref([])

  async function listIntegrations(request: WorkforceIntegrationApiListWorkforceIntegrationsRequest) {
    const data: ListWorkforceIntegrationsResponse = await callReturn(() =>
      getWorkforceIntegrationApi().listWorkforceIntegrations(request)
    )
    integrations.value = data.results
  }

  return {
    listIntegrations,
    listIntegrationsResult: callResult,
    integrations
  }
}

export function useListSyncOperationEvents() {
  const { getWorkforceIntegrationApi, callReturn, callResult } = useAsclepius()

  const syncEventsPaginated = reactive<Paginated<WorkforceIntegrationSyncOperationEvent>>({
    currentPage: 1,
    nextPage: undefined,
    prevPage: undefined,
    totalCount: 0,
    results: []
  })

  async function listSyncEvents(request: WorkforceIntegrationApiListWorkforceIntegrationSyncOperationEventsRequest) {
    request = { ...request, page: syncEventsPaginated.currentPage }

    const data: ListWorkforceIntegrationSyncOperationEventsResponse = await callReturn(() =>
      getWorkforceIntegrationApi().listWorkforceIntegrationSyncOperationEvents(request)
    )

    syncEventsPaginated.results = data.results
    syncEventsPaginated.nextPage = data.next
    syncEventsPaginated.prevPage = data.previous
    syncEventsPaginated.totalCount = data.count
  }

  return {
    listSyncEvents,
    listSyncEventsResult: callResult,
    syncEventsPaginated
  }
}
