import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import GuardianRunner from "@/router/guardian-runner"
import {
  ensureIsAnonymous,
  ensureIsAuthenticated,
  ensureIsCompanyAdminNotActive,
  ensureIsCompanyOnboarded
} from "@/router/guardians"

export const routes = [
  {
    path: "/",
    name: "auth-layout-setup",
    component: () => import("@/layouts/auth/AuthLayout.vue"),
    children: [
      {
        path: "/activate",
        name: "activate",
        component: () => import("@/modules/setup/pages/ActivateAccountSection.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [
            ensureIsAuthenticated,
            ensureIsCompanyOnboarded,
            ensureIsCompanyAdminNotActive
          ])
        }
      },
      {
        path: "/setup",
        name: "setup",
        component: () => import("@/modules/setup/pages/FindAccountSection.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [ensureIsAnonymous])
        }
      },
      {
        path: "/setup/:emailToVerify",
        name: "setup-verify",
        component: () => import("@/modules/setup/pages/VerifyAccountSection.vue"),
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          GuardianRunner.runGuardians(to, from, next, [ensureIsAnonymous])
        },
        props: true
      }
    ]
  }
]
